<template>
  <el-dialog
    v-dialogDrag
    title="详情"
    :visible.sync="visible"
    width="600px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    append-to-body
    :element-loading-background="loadingBackground"
    custom-class="add-concat-dialog"
    v-loading="loading"
    @open="openDialog"
  >
    <el-form ref="addForm" label-width="135px" :model="addForm">
      <el-form-item label="客户名称:" prop="crmName">
        {{ addForm.crmName }}
      </el-form-item>
      <el-form-item prop="materialName" label="物料名称:">
        {{ addForm.materialName }}
      </el-form-item>
      <el-form-item label="物料分类:" prop="classifyId">
        {{ addForm.classifyName }}
      </el-form-item>
      <el-form-item label="是否启用:" prop="status">
        <el-switch
          v-model="addForm.status"
          :active-value="1"
          :inactive-value="0"
          disabled
        >
        </el-switch>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
export default {
  name: "specialMaterialDetail",
  components: {},
  data() {
    return {
      addForm: {
        materialNo: "",
        status: 1,
        classifyId: "",
        crmId: "",
        crmName: ""
      }
    };
  },
  props: {
    detailVisible: {
      type: Boolean,
      default: false
    },
    clickRow: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [base],
  computed: {
    visible: {
      get() {
        return this.detailVisible;
      },
      set(val) {
        this.$emit("update:detailVisible", val);
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    openDialog() {
      this.addForm = { ...this.clickRow };
    },
    closeDialog() {
      this.visible = false;
      this.addForm = {
        materialNo: "",
        status: 1,
        classifyId: "",
        crmId: "",
        crmName: ""
      };
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.add-concat-dialog {
  .el-dialog__body {
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 600px;
    .el-input {
      width: 250px;
    }
  }
}
</style>
