<!--
 * @Description: 新建服务商信息
 * @Author: ChenXueLin
 * @Date: 2021-11-09 18:26:29
 * @LastEditTime: 2022-07-15 16:38:03
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="title"
    :visible.sync="visible"
    width="600px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    append-to-body
    :element-loading-background="loadingBackground"
    custom-class="add-concat-dialog"
    v-loading="loading"
    @open="openDialog"
  >
    <el-form
      ref="addForm"
      label-width="135px"
      :model="addForm"
      :rules="addFormRules"
    >
      <el-form-item label="客户名称:" prop="crmId">
        <remote-search
          v-model="addForm.crmId"
          :showInit="addType == 1 ? true : false"
          :remote="true"
          clearable
          placeholder="客户名称"
          title="客户名称"
          searchValueKey="name"
          :props="{
            id: 'cdcId',
            label: 'customerName'
          }"
          :defaultValue="
            addType == 1
              ? {}
              : {
                  cdcId: clickRow.crmId,
                  customerName: clickRow.crmName
                }
          "
          @change="changeCorp"
          :queryListAPI="queryCustomer"
        ></remote-search>
      </el-form-item>
      <el-form-item prop="materialNo" label="物料名称:">
        <remote-search
          v-model="addForm.materialNo"
          :showInit="addType == 1 ? true : false"
          clearable
          placeholder="物料名称"
          title="物料名称"
          :queryListAPI="queryMaterialList"
          :width="350"
          :defaultValue="
            addType == 1
              ? {}
              : {
                  materialNo: clickRow.materialNo,
                  materialName: clickRow.materialName
                }
          "
          :searchData="{
            isEnableEquipNo: false,
            localMaterial: 1,
            pageIndex: 1,
            pageSize: 200
          }"
        ></remote-search>
      </el-form-item>
      <el-form-item label="物料分类:" prop="classifyId">
        <e6-vr-select
          v-model="addForm.classifyId"
          :data="classifyList"
          placeholder="物料分类"
          title="物料分类"
          clearable
          virtual
          :is-title="true"
          :props="{
            id: 'classifyId',
            label: 'classifyName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="是否启用:" prop="status">
        <el-switch
          v-model="addForm.status"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </el-form-item>
    </el-form>

    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import {
  bindMaterialClassifySpec,
  getMaterialClassifyList,
  updateMaterialClassifySpec,
  queryMaterialList,
  queryCustomer
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "addSpecialMaterial",
  components: {},
  data() {
    return {
      queryMaterialList,
      queryCustomer,
      classifyList: [],
      loading: false,
      addForm: {
        materialNo: "",
        status: 1,
        classifyId: "",
        crmId: "",
        crmName: ""
      },
      addFormRules: {
        materialNo: [
          {
            required: true,
            message: "请选择物料名称",
            trigger: ["blur", "change"]
          }
        ],
        crmId: [
          {
            required: true,
            message: "请选择客户名称",
            trigger: ["blur", "change"]
          }
        ],
        classifyId: [
          {
            required: true,
            message: "请选择物料分类",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: {
    addVisible: {
      type: Boolean,
      default: false
    },
    //1新增2编辑3详情
    addType: {
      type: [Number, String],
      default: 1
    },
    clickRow: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [base],
  computed: {
    visible: {
      get() {
        return this.addVisible;
      },
      set(val) {
        this.$emit("update:addVisible", val);
      }
    },
    title() {
      let res;
      switch (this.addType) {
        case 1:
          res = "新建";
          break;
        case 2:
          res = "编辑";
          break;
        default:
          res = "";
          break;
      }

      return res;
    }
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      try {
        this.loading = true;
        let promiseList = [getMaterialClassifyList({})];
        let [classifyRes] = await Promise.all(promiseList);
        this.classifyList = this.getFreezeResponse(classifyRes, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //打开
    openDialog() {
      if (this.addType != 1) {
        let clickRow = _.cloneDeep(this.clickRow);
        this.addForm = { ...clickRow };
      }
      this.$nextTick(() => {
        this.$refs.addForm.clearValidate();
      });
    },
    changeCorp(val, node) {
      this.addForm.crmName = node.customerName;
    },
    //点击确定
    confirm() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (this.addType == 1) {
            this.bindMaterialClassifySpecReq();
          } else {
            //编辑
            this.updateMaterialClassifySpecReq();
          }
        }
      });
    },
    //新增
    async bindMaterialClassifySpecReq() {
      try {
        this.loading = true;
        let res = await bindMaterialClassifySpec(this.addForm);
        if (res.code == "OK") {
          this.$message.success("新增成功");
          this.$emit("refreshList");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //编辑
    async updateMaterialClassifySpecReq() {
      try {
        this.loading = true;
        let res = await updateMaterialClassifySpec(this.addForm);
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.$emit("refreshList");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    closeDialog() {
      this.visible = false;
      this.addForm = {
        materialNo: "",
        status: 1,
        classifyId: "",
        crmId: "",
        crmName: ""
      };
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.add-concat-dialog {
  .el-dialog__body {
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 600px;
    .el-input {
      width: 250px;
    }
  }
}
</style>
