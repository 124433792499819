var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":_vm.title,"visible":_vm.visible,"width":"600px","before-close":_vm.closeDialog,"close-on-click-modal":false,"append-to-body":"","element-loading-background":_vm.loadingBackground,"custom-class":"add-concat-dialog"},on:{"update:visible":function($event){_vm.visible=$event},"open":_vm.openDialog}},[_c('el-form',{ref:"addForm",attrs:{"label-width":"135px","model":_vm.addForm,"rules":_vm.addFormRules}},[_c('el-form-item',{attrs:{"label":"客户名称:","prop":"crmId"}},[_c('remote-search',{attrs:{"showInit":_vm.addType == 1 ? true : false,"remote":true,"clearable":"","placeholder":"客户名称","title":"客户名称","searchValueKey":"name","props":{
          id: 'cdcId',
          label: 'customerName'
        },"defaultValue":_vm.addType == 1
            ? {}
            : {
                cdcId: _vm.clickRow.crmId,
                customerName: _vm.clickRow.crmName
              },"queryListAPI":_vm.queryCustomer},on:{"change":_vm.changeCorp},model:{value:(_vm.addForm.crmId),callback:function ($$v) {_vm.$set(_vm.addForm, "crmId", $$v)},expression:"addForm.crmId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"prop":"materialNo","label":"物料名称:"}},[_c('remote-search',{attrs:{"showInit":_vm.addType == 1 ? true : false,"clearable":"","placeholder":"物料名称","title":"物料名称","queryListAPI":_vm.queryMaterialList,"width":350,"defaultValue":_vm.addType == 1
            ? {}
            : {
                materialNo: _vm.clickRow.materialNo,
                materialName: _vm.clickRow.materialName
              },"searchData":{
          isEnableEquipNo: false,
          localMaterial: 1,
          pageIndex: 1,
          pageSize: 200
        }},model:{value:(_vm.addForm.materialNo),callback:function ($$v) {_vm.$set(_vm.addForm, "materialNo", $$v)},expression:"addForm.materialNo"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"物料分类:","prop":"classifyId"}},[_c('e6-vr-select',{attrs:{"data":_vm.classifyList,"placeholder":"物料分类","title":"物料分类","clearable":"","virtual":"","is-title":true,"props":{
          id: 'classifyId',
          label: 'classifyName'
        }},model:{value:(_vm.addForm.classifyId),callback:function ($$v) {_vm.$set(_vm.addForm, "classifyId", $$v)},expression:"addForm.classifyId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"是否启用:","prop":"status"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},model:{value:(_vm.addForm.status),callback:function ($$v) {_vm.$set(_vm.addForm, "status", $$v)},expression:"addForm.status"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.closeDialog}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }