<!--
 * @Description:服务商信息列表
 * @Author: ChenXueLin
 * @Date: 2022-03-04 09:28:40
 * @LastEditTime: 2022-07-15 16:02:31
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item prop="crmIdList">
              <remote-search
                v-model="searchForm.crmIdList"
                :remote="true"
                clearable
                placeholder="客户名称"
                title="客户名称"
                searchValueKey="name"
                multiple
                :props="{
                  id: 'cdcId',
                  label: 'customerName'
                }"
                :queryListAPI="queryCustomer"
              ></remote-search>
            </el-form-item>
            <el-form-item prop="materialNoList">
              <remote-search
                v-model="searchForm.materialNoList"
                clearable
                multiple
                placeholder="物料名称"
                title="物料名称"
                :searchData="{
                  localMaterial: 1,
                  pageIndex: 1,
                  pageSize: 200
                }"
              ></remote-search>
            </el-form-item>
            <el-form-item class="search-item--1" prop="materialNo">
              <el-input
                v-model="searchForm.materialNo"
                placeholder="物料编码"
                title="物料编码"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
          <i class="e6-icon-clear_line" title="删除" @click="handleDelete"></i>
        </template>
      </table-title>
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
    </div>
    <add-special-material
      :addVisible.sync="addVisible"
      @refreshList="handleSearch"
      :clickRow="clickRow"
      :addType="addType"
    ></add-special-material>
    <special-material-detail
      :detailVisible.sync="detailVisible"
      :clickRow="clickRow"
    ></special-material-detail>
    <!-- 操作日志 -->
    <log-dialog
      :logVisible.sync="logVisible"
      :businessId="clickRow.classifyRelationId"
      logType="10"
    ></log-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import {
  queryCustomer,
  queryListPageMaterialClassifySpec,
  deleteMaterialClassifySpec
} from "@/api";
import AddSpecialMaterial from "./addSpecialMaterial.vue";
import SpecialMaterialDetail from "./specialMaterialDetail.vue";
import LogDialog from "@/components/logDialog.vue";
export default {
  name: "specialMaterial",
  data() {
    return {
      queryCustomer,
      queryListAPI: queryListPageMaterialClassifySpec,
      searchForm: {
        crmIdList: [],
        materialNoList: [],
        materialNo: "",
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      loading: false,
      columnData: [
        {
          fieldName: "crmName",
          display: true,
          fieldLabel: "客户名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialName",
          display: true,
          fieldLabel: "物料名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNo",
          display: true,
          fieldLabel: "物料编码",
          width: 110,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "classifyName",
          display: true,
          fieldLabel: "物料分类",
          width: 110,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "productLineName",
          display: true,
          fieldLabel: "产品线",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "statusName",
          display: true,
          fieldLabel: "单据状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedUserName",
          display: true,
          fieldLabel: "操作人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedTime",
          display: true,
          fieldLabel: "修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      addVisible: false,
      addType: 1,
      clickRow: {},
      logVisible: false,
      selColumnId: [],
      detailVisible: false
    };
  },
  mixins: [listPage, base, listPageReszie],
  components: { AddSpecialMaterial, SpecialMaterialDetail, LogDialog },
  compute: {},
  watch: {},
  created() {
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        },
        {
          label: "详情",
          id: 2
        },
        {
          label: "删除",
          id: 3
        },
        {
          label: "操作日志",
          id: 4
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      this.clickRow = row;
      this.addVisible = val.id == 1 ? true : false;
      this.addType = 2;
      this.detailVisible = val.id == 2 ? true : false;
      this.logVisible = val.id == 4 ? true : false;
      if (val.id == 3) {
        this.selColumnId = [
          {
            id: row.classifyRelationId,
            versionNumber: row.versionNumber
          }
        ];
        this.handleDelete();
      }
    },
    //新增
    handleAdd() {
      this.addVisible = true;
      this.addType = 1;
    },
    //表格选中数据
    handleSelectionChange(column) {
      this.selColumnId = column.map(item => {
        return Object.assign(
          {},
          {
            id: item.classifyRelationId,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    //批量修改
    handleBatchEdit() {
      if (!this.serviceIdList.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      this.addVisible = true;
      this.addType = 4;
    },
    handleDelete() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    async deleteReq() {
      try {
        this.loading = true;
        let res = await deleteMaterialClassifySpec({
          classifyRelationList: this.selColumnId
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
